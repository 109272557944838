import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import styles from '../styles/header.module.css';
import {Nav, Navbar} from "react-bootstrap";


const Header = forwardRef((props, ref) => {
    const [state, setState] = useState(0);

    useImperativeHandle(ref, () => {
        return {
            getState() {
                return state
            },
            showBrand() {
                setState(1);
            },
            showNav() {
                setState(2);
            }
        };
    }, [state]);

    useEffect(() => {}, [state]);

    return (
        // TODO add in final version: flex-column flex-md-row justify-content-start justify-content-sm-between align-items-start  align-items-md-center
        <header ref={ref} className="d-flex flex-row justify-content-between align-items-center align-content-center navbar-expand-lg navbar">
            <Navbar.Brand>
                <h1 className={styles.brandingWrapper} style={{'opacity': state > 0 ? 1 : 0 }}>
                    <a href="https://sergiorebelo.work" rel="noopener noreferrer" >Sérgio M. Rebelo</a>
                </h1>
            </Navbar.Brand>
            <Nav className={styles.brandingNav + " headerLink d-flex"} style={{'opacity': state > 1 ? 1 : 0 }}>
                <Nav.Link href="/archive" className={styles.brandingLink + " disable " + styles.arxiv}> Archive </Nav.Link>
                <Nav.Link href="/about" className={styles.brandingLink + " disable " + styles.about}> About </Nav.Link>
                <Nav.Link href="mailto:contact@sergiorebelo.work?subject=Hi Sérgio 👋" className={styles.brandingLink + " roundedBorder " + styles.contact}> Contact </Nav.Link>
            </Nav>
        </header>
    );
});

export default Header;