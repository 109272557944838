import React, {useEffect, useRef, useState} from "react";
import ReactGA from 'react-ga4';

import "bootstrap/dist/css/bootstrap-grid.css";
import "./styles/main.css";

import LoadingScreen from "./components/LoadingScreen";
import Home from "./pages/Home";
import styles from "./styles/homepage.module.css";
import Header from "./components/Header";



function App() {
    const loadingScreenRef = useRef(null);
    const headerRef = useRef(null);
    const [readyState, setReadyState] = useState(0);
    const [state, setState] = useState(0);

    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

    useEffect(() => {
        if (loadingScreenRef.current) {
            loadingScreenRef.current.update(readyState);
        }

        const intervalHeader = setInterval(() => {
            if (loadingScreenRef.current) {
                const current = loadingScreenRef.current.currentState();
                if (headerRef.current) {
                    const s = headerRef.current.getState();
                    if (current > 90 && s === 1 ) {
                        headerRef.current.showNav();
                        clearInterval(intervalHeader)
                    } else if (current > 40  && s === 0) {
                        headerRef.current.showBrand();
                    } else if (s === 2) {
                        clearInterval(intervalHeader)
                    }

                    // handle readyState errors
                    if (document.readyState === "complete") {
                        setReadyState(100);
                    }
                }
            }
        }, 100);

        document.onreadystatechange = (e) => {
            switch (document.readyState) {
                case "loading":
                    setReadyState(20);
                    break;
                case "interactive":
                    setReadyState(70);
                    break;
                case "complete":
                    setReadyState(100);
                    break;
                default:
                    break;
            }
        }
        return () => clearInterval(intervalHeader);
    }, [readyState]);

    const loadingEnd = () => {
        const el = loadingScreenRef.current.remove();
         el.ontransitionend = () => {
            setState(1);
        }
    }

    return (
        <section className={styles.homepageOuter}>
            <Header ref={headerRef}/>
            {state === 0 ? <LoadingScreen ref={loadingScreenRef} vp={100} onLoadingComplete={loadingEnd}/> : null}
            <Home/>
        </section>
    );
}

export default App;
