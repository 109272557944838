import React, {useEffect, useRef, useState} from "react";
import ReactGA from 'react-ga4';
import {Row, Col} from 'react-bootstrap';
import styles from "../styles/homepage.module.css";
import info from '../content/homepage.md';
import DOMPurify from "dompurify";
import Sketch from "../components/Sketch";

function Home() {
    const [content, setContent] = useState("");
    const [canvasSize, setCanvasSize] = useState({width: window.innerWidth, height: window.innerHeight});
    const canvasRef = useRef(null);
    ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "home"
    });

    useEffect(() => {
        fetch(info)
            .then((res) => res.text())
            .then((text) => setContent(DOMPurify.sanitize(text)));

        window.addEventListener("resize", resizeCanvas);

        // window.addEventListener("click", () => {
        //     canvasRef.current.clear();
        // });

        return () => {
            window.removeEventListener("resize", resizeCanvas);
        };
    }, [content]);

    const resizeCanvas = () => {
        setTimeout(() => {
            setCanvasSize({width: window.innerWidth, height: window.innerHeight});
        }, 10);
    }

    return(
        <React.Fragment>
            <Row>
                <Col xs={12} className="hero">
                    <div className={styles.hero} dangerouslySetInnerHTML={{ __html: content }} />
                </Col>
            </Row>
            <Sketch ref={canvasRef} id={styles.canvasContainer} width={canvasSize.width} height={canvasSize.height} pos={{width:null, height:null}}/>
        </React.Fragment>
    )
}



export default Home;