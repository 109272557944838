import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';

const map = (n, start, stop, nstart, nstop) => {
    return (n - start) / (stop - start) * (nstop - nstart) + start;
}

const Sketch = forwardRef ((props, ref) => {
    const canvasRef = useRef(null);
    const colorPrimary = getComputedStyle(document.documentElement).getPropertyValue('--color-primary');
    const [pos, setPos] = useState([]);
    const [pmouse, setPMouse] = useState({x:null, y:null});
    const max = 300, inc = 0.1;
    const [c, setColor] = useState(Math.round(Math.random()*360));
    const [cTarget, setTargetColor] = useState(Math.round(Math.random()*360));
    const [ready, setReady] = useState(true);

    useImperativeHandle(ref, () => {
        return {
            current () {
                return canvasRef;
            },
            isReady (v = true) {
                setReady(v);
            },
            clear () {
                setPos([]);
            }
        };
    }, []);

    useEffect(() => {
        const update = (ev) => {
            const x = ev.clientX/props.width, y =  ev.clientY/props.height;
            if ((pmouse.x !== x || pmouse.y !== y) && ready) {
                setPMouse( {x: x, y: y});
                let arr = [...pos];
                if (arr.length > max) {
                    arr.shift();
                }
                const n = next(x, y);
                setPos([...arr, n]);
            }
        }

        const createRandomDraw = (inc = 0.1, m= 0.2) => {
            let arr = [];
            let posX = m + Math.random() * (1-m*2);
            let posY = m + Math.random() * (1-m*2);
            let n = next(posX, posY)
            arr.push(n);
            for (let i = 0; i<max; i++) {
                const dir = Math.round(Math.random()*5.4);
                posX += dir === 1 || dir >= 3 ? -inc+(Math.random()*inc*2) : 0;
                posY += dir === 2 || dir >= 3 ? -inc+(Math.random()*inc*2) : 0;
                posX = Math.max(0, Math.min(posX, 1));
                posY = Math.max(0, Math.min(posY, 1));
                n = next(posX,posY);
                arr.push(n);
            }
            setPos(arr);
        }

        const next = (x, y, r=50) => {
            let n = {x: x, y: y, wid:r, c:c};
            const cInc = cTarget > c ? inc : -inc;
            setColor(c + cInc);
            if (Math.abs(cTarget-c) < 2) {
                setTargetColor(Math.round(Math.random()*360));
            }
            return n;
        }

        const draw = (context) => {
            context.fillStyle = colorPrimary;
            context.fillRect(0, 0, context.canvas.width, context.canvas.height);
            for (let i in pos) {
                let rect = pos[i];
                context.beginPath();
                let x = rect.x * props.width;
                let y = rect.y * props.height;
                context.arc(x,y, rect.wid, 0, 2 * Math.PI);
                // context.rect(rect.x, rect.y, rect.wid, rect.wid);
                const alpha = map(i, 0, max, 0, 1);
                context.fillStyle = `HSLA(${rect.c},100%,50%,${alpha})`;
                context.fill();
            }
        }

        if (props.width < 800 && pos.length < 1) {
            createRandomDraw();
        }
        window.onmousemove = update;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        draw(context);
        return () => {
            window.onmousemove = null;
        }

    }, [canvasRef, pos, pmouse, c, setTargetColor, ready, props.width, props.height, cTarget, colorPrimary]);

    return (
        <canvas ref={canvasRef} id={props.id} width={props.width} height={props.height} />
    );
});

export default Sketch;