import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import styles from '../styles/loading.module.css';


const regularPolygon = (nv = 2, vp=100) => {
    const arr = [];
    const r = vp/2 - (vp*0.02);
    const inc = Math.PI * 2 / nv;
    for (let i=0; i<nv; i++) {
        let x = vp/2 + r * Math.cos(i *  inc);
        let y = vp/2 + r * Math.sin(i *  inc);
        arr.push(`${Math.round(x)},${Math.round(y)}`);
    }
    return arr.join(' ');
}

const LoadingScreen = forwardRef(({vp= 100, onLoadingComplete = () => {}}, ref) => {
    let interval = 30, inc = 4, div= 5, transf = 10;
    // eslint-disable-next-line
    let [viewport, setViewport] = useState(vp);
    let [target, setTarget] = useState(0);
    let [current, setCurrent] = useState(target);
    let [opacity, setOpacity] = useState(1);
    let [pos, setPos] = useState({x:null, y:null})
    const [pts, setPts] = useState(``);
    const loadingPointerRef = useRef(null);
    const colorPrimary = getComputedStyle(document.documentElement).getPropertyValue('--color-secondary');

    useImperativeHandle(ref, () => {
        return {
            update(t) {
                setTarget(t);
            },
            currentState() {
                return current;
            },
            remove() {
                setOpacity(0);
                return document.querySelector(`.${styles.loadingOuter}`);
            }
        };
    }, [current]);

    const updateMousePosition = ev => {
        setPos({ x: ev.clientX, y: ev.clientY });
        if (loadingPointerRef.current.style && pos.y !== null && pos.x !== null) {
            loadingPointerRef.current.style.top = `${(pos.y-viewport/2)}px`;
            loadingPointerRef.current.style.left = `${(pos.x-viewport/2)}px`;
        }
    };

    useEffect(() => {
        if (current <= target) {
            setTimeout(() => {
                setCurrent(current + inc);
                // setViewport(viewport + (viewport * 0.1));
                let pts = regularPolygon(Math.max(2, Math.round(current / 5)), viewport);
                setPts(pts);
                if (current >= 100 && opacity === 1) {
                    onLoadingComplete();
                }
            }, interval);
        }

    }, [inc, interval, onLoadingComplete, viewport, target, current, pos, opacity]);

    return (
        <div className={styles.loadingOuter} ref={ref} style={{'opacity': opacity}} onMouseMove={updateMousePosition}>
            <div className={styles.loadingInner} ref={loadingPointerRef}>
                <svg width={viewport} height={viewport} xmlns="http://www.w3.org/2000/svg">
                    {(current/div) < transf ?
                        <polygon points={pts} fill={colorPrimary} stroke={colorPrimary} strokeWidth={4} strokeMiterlimit={4}
                                 strokeLinecap="round" strokeLinejoin="round">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                from={`0 ${viewport / 2} ${viewport / 2}`}
                                to={`360 ${viewport / 2} ${viewport / 2}`}
                                dur="2s"
                                repeatCount="indefinite"/>
                        </polygon> :
                        <circle cx={viewport/2} cy={viewport/2} r={viewport/2} />
                    }
                </svg>
            </div>
        </div>
    );
});

export default LoadingScreen;